<template>
  <div>
    <router-view></router-view>
    <van-tabbar
      v-model="active"
      active-color="#FFA514"
      inactive-color="#999999"
      :placeholder="true"
    >
      <van-tabbar-item to="/">
        <span>首页</span>
        <template #icon="props">
          <img :src="props.active ? icon.homeActive : icon.home" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item to="create">
        <span>创作者</span>
        <template #icon="props">
          <img :src="props.active ? icon.createActive : icon.create" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item to="profit">
        <span>收益</span>
        <template #icon="props">
          <img :src="props.active ? icon.profitActive : icon.profit" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item to="mine">
        <span>我的</span>
        <template #icon="props">
          <img :src="props.active ? icon.mineActive : icon.mine" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: 0,
      icon: {
        homeActive: require("@/assets/home.png"),
        home: require("@/assets/homeNO.png"),
        createActive: require("@/assets/create.png"),
        create: require("@/assets/createNo.png"),
        profitActive: require("@/assets/profit.png"),
        profit: require("@/assets/profitNo.png"),
        mineActive: require("@/assets/mine.png"),
        mine: require("@/assets/mineNo.png"),
      },
    };
  },
  watch: {
    $route: {
      handler(to, from) {
        if (to.name === "index") {
          this.active = 0;
        } else if (to.name === "create") {
          this.active = 1;
        } else if (to.name === "profit") {
          this.active = 2;
        } else if (to.name === "mine") {
          this.active = 3;
        }
      },
      immediate: true,
    },
  },
};
</script>

<style></style>
