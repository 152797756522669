import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "@/views/homeView/homeView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "",
    name: "home",
    component: HomeView,
    redirect: "index",
    children: [
      {
        path: "/",
        name: "index",
        component: () => import("@/views/index/index.vue"),
      },
      {
        path: "create",
        name: "create",
        component: () => import("@/views/createView/createView.vue"),
      },
      {
        path: "profit",
        name: "profit",
        component: () => import("@/views/profitView/profitView.vue"),
      },
      {
        path: "mine",
        name: "mine",
        component: () => import("@/views/mineView/mineView.vue"),
      },
    ],
  },
  {
    path: "/setting",
    name: "setting",
    component: () => import("@/views/settingUserInfo/settingUserInfo.vue"),
  },
  {
    path: "/novice",
    name: "novice",
    component: () => import("@/views/NoviceView/noviceView.vue"),
  },
  {
    path: "/settingconfig",
    name: "settingconfig",
    component: () => import("@/views/settingConfig/settingConfigView.vue"),
  },
  {
    path: "/service",
    name: "service",
    component: () => import("@/views/Service/serviceView.vue"),
  },
  {
    path: "/privacy",
    name: "privacy",
    component: () => import("@/views/Privacy/privacyView.vue"),
  },
  {
    path: "/changecontacts",
    name: "changecontacts",
    component: () => import("@/views/changeContacts/changeContacts.vue"),
  },
  {
    path: "/tixian",
    name: "tixian",
    component: () => import("@/views/tixian/tixianView.vue"),
  },
  {
    path: "/mywallet",
    name: "mywallet",
    component: () => import("@/views/myWallet/myWalletView.vue"),
  },
  {
    path: "/bindview",
    name: "bindview",
    component: () => import("@/views/bindView/bindView.vue"),
  },
  {
    path: "/shouyibaobiao",
    name: "shouyibaobiao",
    component: () => import("@/views/shouyibaobiao/shouyibaobiao.vue"),
  },
  {
    path: "/notice",
    name: "notice",
    component: () => import("@/views/NoticeView/noticeView.vue"),
  },
  {
    path: "/noticedetail/:id",
    name: "noticedetail",
    component: () => import("@/views/NoticeView/noticeDetails.vue"),
  },
  {
    path: "/teamview",
    name: "teamview",
    component: () => import("@/views/teamView/teamView.vue"),
  },
  {
    path: "/picdetail/:id",
    name: "picdetail",
    component: () => import("@/views/picDetailView/picDetailView.vue"),
  },
  {
    path: "/invitation",
    name: "invitation",
    component: () => import("@/views/invitation/invitationView.vue"),
  },
  {
    path: "/profitdetail",
    name: "profitdetail",
    component: () => import("@/views/profitView/profitDetailView.vue"),
  },
  {
    path: "/upload",
    name: "upload",
    component: () => import("@/views/upLoadView/upLoadView.vue"),
  },
  {
    path: "/agreement",
    name: "agreement",
    component: () => import("@/views/agreementView/agreementView.vue"),
  },
  {
    path: "/examine",
    name: "examine",
    component: () => import("@/views/examineView/examineView.vue"),
  },
  {
    path: "/recycle",
    name: "recycle",
    component: () => import("@/views/recycleView/recycleView.vue"),
  },
  {
    path: "/mountindex",
    name: "mountindex",
    component: () => import("@/views/mountView/mountIndex.vue"),
  },
  {
    path: "/mountview",
    name: "mountindex",
    component: () => import("@/views/mountView/mountView.vue"),
  },
  {
    path: "/register",
    name: "register",
    component: () => import("@/views/register/register.vue"),
  },
  {
    path: "/zhuanji",
    name: "zhuanji",
    component: () => import("@/views/zhuanjiView/zhuanjiView.vue"),
  },
  {
    path: "/cancellation",
    name: "cancellation",
    component: () => import("@/views/cancellationView/cancellationView.vue"),
  },
  {
    path: "/movepic/:id",
    name: "movepic",
    component: () => import("@/views/movePicView/movePicView.vue"),
  },
  {
    path: "/zhuanjiadd",
    name: "movepic",
    component: () => import("@/views/zhuanjiView/zhuanjiAdd.vue"),
  },
  {
    path: "/loadding",
    name: "loadding",
    component: () => import("@/views/lodding/lodding.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
