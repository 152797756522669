<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
// import { userLogin } from "@/api/user";
import { isWXBrowser, getWechatCode } from "@/utils/common";
export default {
  methods: {
    // async login() {
    //   const res = await userLogin({ code: "123456" });
    //   console.log(res);
    //   localStorage.setItem("openid", res.data.data.openid);
    //   if (res.data.data.token != "") {
    //     localStorage.setItem("token", "bearer  " + res.data.data.token);
    //   } else if (res.data.data.state === 0) {
    //     console.log(5678);
    //     this.$router.push("/cancellation");
    //   }
    // },
  },
  created() {
    // // this.login();
    // if (!localStorage.getItem("token")) {
    //   getWechatCode();
    // } else if (
    //   localStorage.getItem("token") &&
    //   localStorage.getItem("is_destroye") == 1
    // ) {
    //   this.$router.replace("/cancellation");
    // } else if (
    //   localStorage.getItem("token") &&
    //   localStorage.getItem("have_redirect") == 1
    // ) {
    //   this.$router.replace("/register");
    // }
    // this.setMeta();
    // this.getHomeView();
    // // console.log(this.title);
    // document.addEventListener("WeixinJSBridgeReady", function onBridgeReady() {
    //   WeixinJSBridge.call("hideToolbar");
    // });
  },
};
</script>
<style lang="less"></style>
