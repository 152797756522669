// 判断是否微信环境

export const isWXBrowser = (() => {
  const ua = window.navigator.userAgent.toLowerCase();

  return /(micromessenger|webbrowser)/g.test(ua);
})();

import axios from "axios";
// 判断是否为微信环境
const isWechat = () => {
  return (
    String(navigator.userAgent.toLowerCase().match(/MicroMessenger/i)) ===
    "micromessenger"
  );
};
export const getUrlCode = () => {
  // 截取url中的code方法
  var url = location.search;
  var theRequest = new Object();
  if (url.indexOf("?") != -1) {
    var str = url.substr(1);
    var strs = str.split("&");
    for (var i = 0; i < strs.length; i++) {
      theRequest[strs[i].split("=")[0]] = strs[i].split("=")[1];
    }
  }
  return theRequest;
};
export const getWechatCode = () => {
  let appid = "wxf919697d2eeb43d0"; //微信APPid
  window.location.href =
    "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
    appid +
    "&redirect_uri=" +
    encodeURIComponent("https://aitugzh.iituku.com/loadding") +
    "&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect";
};
